import { useCallback, useEffect } from "react";
import { useAtom } from "jotai";

import { popupAtom } from "./popupAtom";
import { PopupProps } from "./Popup.d";

export const usePopup = () => {
	const [queue, setQueue] = useAtom(popupAtom);

	const handleClose = useCallback(() => setQueue(q => q.length === 1 ? [] : q.slice(1)), [setQueue]);

	// Dequeue alerts every (timeout) ms
	useEffect(() => {
		if (queue.length > 0) {
			const [{ timeout = 5000 } = { timeout: 5000 }] = queue;
			setTimeout(handleClose, timeout);
		}
	}, [handleClose, queue, setQueue]);

	return {
		isOpen: queue.length > 0,
		props: queue[0] as PopupProps,
		onClose: handleClose
	};
};