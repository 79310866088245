import { useRef } from "react";

import { useIsoLayoutEffect } from "~/client/hooks";

import { PORTAL_ROOT_SELECTOR } from "./constants";

export const usePortal = () => {
	const ref = useRef<Element | null>(null);

	useIsoLayoutEffect(() => {
		const portalRoot = document.querySelector(PORTAL_ROOT_SELECTOR);

		ref.current = portalRoot;
	}, []);

	return ref;
};