import { FC, PropsWithChildren } from "react";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";

import { Icon } from "../../widgets";

import Portal from "../Portal";

import { usePopup } from "./usePopup";
import "./styles.scss";

const Popup: FC<PropsWithChildren> = () => {
	const {
		isOpen,
		props,
		onClose
	} = usePopup();

	return (
		<Portal isOpen={isOpen}>
			<dialog open style={{ borderLeft: `12px solid ${props?.color}` }} className="popup">
				<div className="popup__icon">
					{props?.icon && <Icon icon={props.icon} style={{ color: props?.color }} />}
				</div>
				<div className="popup__content">
					{props?.title && <h4>{props.title}</h4>}
					{props?.message.split("\n").map((line, i) => <p key={i}>{line}</p>)}
				</div>
				<div className="popup__close">
					<button onClick={onClose} aria-label="Close popup">
						<Icon icon={faTimes} />
					</button>
				</div>
			</dialog>
		</Portal>
	);
};

export default Popup;